import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'ANYONECANCODE.IN ANYBODYCANCODE.IN SMK SOLUTIONS', content: ' A DRIVE FOR TRAINING THE NON-TECH YOUTHS TO BECOME CODERS BY SMK SOLUTIONS AND LAUNCHING THE PROGRAM FOR FREE AND VOLUNTARILY PROVIDING ALL THE INFRASTRUCTURE LIKE SITTING SPACE AND LAPTOPS. FREE TEACHING FROM VERY WELL EXPERIENCED SOFTWARE ENGINEERS.' },
              { name: 'code coding anyone anybody smk solutions shiddu mageppa learn programming india curiosity computer programming no degree non technical students youth jobless training software development html css python web design web site web development mentoring suggestion feedback scratch programming shiddu@gmail.com srihari shivaraj', content: 'anyonecancode any one can code india coding programming, to learn computer programming and write code does not require any degree or certificate. You can become a software programmer or engineer just by learning coding. It requires curiosity and a goal to achieve, yes it also requires teachers we are here for the same' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
